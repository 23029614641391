:root {
	--text-font: Inter, sans-serif;
	--primary-bg-color: #282c34;
	--secondary-bg-color: #575969;
	box-sizing: border-box;
}

.App {
	font-family: var(--text-font);
	text-align: center;
	display: flex;
	height: 100vh;
}

header {
	display: none;
}

header h1 {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #d9d9e3;
	margin: auto;
}

.menu button {
	background: none;
	border: none;
}

nav {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;
	height: 100vh;
}

.navItems {
	padding: 8px;
	width: 100%;
	max-width: 340px;
}

.navPromptWrapper {
	overflow: auto;
	width: 288px;
}

.navPromptWrapper .categoryContainer:not(:first-child) {
	margin-top: 20px;
}

.navPrompt {
	display: flex;
	align-items: center;
	padding: 10px 12px;
	text-align: left;
	position: relative;
}

.navPrompt div {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
}

.navPrompt input {
	background: none;
}

.navPrompt button {
	background: none;
	border: none;
}

.navPrompt button:hover {
	background: none;
	border: none;
}

.visible {
	visibility: visible;
}

.hidden {
	visibility: hidden;
}

.navPrompt-item {
	display: flex;
	align-items: center;
	column-gap: 8px;
	text-decoration: none;
	color: #48413f;
}

.navPrompt-item.active,
.navPrompt-item:hover {
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;
}

.navPrompt p {
	font-size: 14px;
	line-height: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 200px;
	margin: 0;
	text-align: justify;
}

.navPrompt span {
	animation: fadeInChar 1s ease-in forwards;
	opacity: 0;
}

#navPrompt-input {
	color: #48413f;
	background: #f6f6f4;
	width: 100%;
}

#botMessage pre {
	margin: 0;
	padding: 0;
	text-indent: 0;
	white-space: pre-wrap;
	top: -45px;
	font-size: 16px;
}

pre {
	font-family: var(--text-font);
}

.react-markdown {
	margin: 0;
	max-width: 100%;
}

.react-markdown p {
	margin: 0;
	text-align: left;
}

@keyframes fadeInChar {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.navCloseIcon {
	width: 20%;
	margin: 10px;
}

nav svg {
	float: left;
}

.sideMenu {
	top: 0;
	left: 0;
	height: 100%;
	padding: 0 8px;
	display: flex;
	flex-direction: column;
	transition: width 0.35s ease-in-out;
	position: relative;
	margin-right: 24px;
	background-color: #f6f6f4;
}

.sideMenu-title {
	font-size: 26px;
	font-weight: 600;
	line-height: 34px;
	color: #48413f;
	margin: 0;
	padding: 0;
	overflow-wrap: anywhere;
	word-break: normal;
}

.sideMenu-collapse-button {
	width: 32px;
	height: 28px;
	border-radius: 10px;
	background-color: transparent;
	border: 1px solid #c6c2bf;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 8px;
}

.sideMenu-collapse-button:hover {
	background-color: transparent;
	border: 1px solid #c6c2bf;
	border-radius: 20%;
}

.navPrompt.active,
.navPrompt:hover {
	cursor: pointer;
	background-color: #2e7918;
	color: #ffffff;
	border-radius: 10px;
}

.chatBox {
	flex: 1;
	line-height: 24px;
	color: #d1d5db;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#introsection::before,
#introsection::after {
	float: left;
	color: rgb(0, 134, 244);
}

#introsection {
	text-align: left;
	padding: 20px;
}

#introsection h1 {
	padding-top: 20px;
	line-height: 33px;
}

#introsection h2 {
	font-size: 16px;
	font-weight: 400;
}

#introsection ul {
	list-style-type: square;
}

#introsection pre {
	margin: 0;
	display: inline;
}

#introsection a {
	color: rgb(0, 134, 244);
}

#statusMessage a {
	color: rgb(0, 134, 244);
}

#hyperlinks a {
	color: rgb(255, 255, 255);
}

.chatLog {
	margin-right: 45px;
}

.chatLogRowWrapper {
	overflow-y: auto;
	max-width: 800px;
	width: 80%;
	padding: 0 20px;
	box-sizing: content-box;
	height: -webkit-fill-available;
}

.userSVG {
	transform: scale(0.6);
}

.botResponseContainer {
	position: relative;
}

.stop-message {
	position: fixed;
	bottom: 90px;
	right: 35px;
	width: fit-content;
	padding: 10px 15px;
	border-radius: 10%;
	border: 1px solid rgb(86, 88, 105);
	background-color: rgb(52, 53, 65);
	color: rgb(217, 217, 217);
	cursor: pointer;
}

.errorMessage {
	color: #ef4444;
	font-size: 16px;
	line-height: 24px;
}

#avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	object-fit: contain;
	object-position: center;
}

.intro-form {
	width: 100%;
	min-width: 400px;
	border: 1px solid #e5e4e1;
	border-radius: 8px;
}

.intro-form-textarea {
	outline: none;
	border: none;
	overflow-y: auto;
	resize: none;
	line-height: 28px;
	color: #48413f;
	padding: 0;
	max-height: 200px;
}

.inputForm {
	display: flex;
	justify-content: center;
	margin-top: 8px;
}

.inputPromptContainer {
	max-height: 324px;
	padding-bottom: 24px;
	margin: 0px;
	position: relative;
}

.inputPromptWrapper {
	background-color: blueviolet;
	padding: 5px;
	position: relative;
	width: 80%;
	max-height: 160px;
	background-color: #41414e;
	border-radius: 5px;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: row;
	align-items: center;
}

.inputPromptTextarea {
	flex: 1 1 auto;
	resize: none;
	background-color: #41414e;
	color: #fff;
	font-size: 16px;
	outline: none;
	border: none;
	overflow-y: auto;
	padding-right: 35px;
	max-height: 140px;
}

form button {
	background: rgb(65, 65, 78);
	border: none;
	flex-shrink: 0; /* don't let the button shrink */
	flex-grow: 0; /* don't let the button grow */
	margin-left: 10px; /* space between button and textarea */
	width: 35px;
	height: 30px;
}

.submit-prompt-btn {
	background-color: transparent;
	border: none;
	cursor: pointer;
}

.loginContainer {
	background: var(--primary-bg-color);
	width: 100%;
	height: 100%;
	font-family: var(--text-font);
}

.loginContainerContent {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}

.loginContainerContent svg {
	padding-bottom: 30px;
}

.loginContainer h1 {
	font-size: 30px;
	margin: 0;
}

.loginContainer p {
	font-size: 18px;
	line-height: 5px;
}

.loginButtonWrapper {
	display: flex;
	align-items: center;
	column-gap: 15px;
	margin-top: 20px;
}

#loginButton {
	padding: 10px 25px;
	border-radius: 7px;
	color: #fff;
	background: #10a37f;
	border: none;
	font-size: 16px;
	cursor: pointer;
}

#loginButton:hover {
	background-color: rgb(26, 127, 100);
}

#introsection {
	font-size: 14px;
}

#introsection h1,
pre {
	font-size: 16px;
	white-space: pre-wrap;
}

#introsection h2 {
	font-size: 14px;
}

.introContainer {
	display: flex;
	justify-content: center;
}

.chatSessionNameContainer {
	width: 80%;
	text-align: left;
}

.chatSessionNameContainer h3 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.chatContainer {
	flex: 1;
	background-color: #fff;
	border-radius: 12px;
	box-shadow: rgba(56, 49, 47, 0.03) 1px 1px 0px 2px;
	display: flex;
	flex-direction: column;
	height: 92%;
	gap: 20px;
}

/* Add the following classNames to style the UserAvatar component */

.user-avatar-container {
	position: relative;
	display: inline-block;
}

.avatar-button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.avatar-image {
	width: 40px;
	height: 40px;
	border-radius: 8px;
	object-fit: cover;
}

.dropdown-container {
	position: absolute;
	top: 100%;
	right: 0;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	z-index: 1;
	overflow: hidden;
	max-width: 368px;
	min-width: 192px;
	background-color: #fff;
	border-radius: 8px;
	box-sizing: border-box;
	border: 1px solid #d4d2d0;
	padding: 4px 0;
}

.dropdown-menu {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	max-width: 368px;
	overflow: hidden;
}

.dropdown-item {
	padding: 10px 16px;
	background-color: white;
	border: none;
	text-align: left;
	width: 100%;
	align-items: center;
	color: #38312f;
	cursor: pointer;
	display: flex;
	flex-grow: 1;
	font-size: 15px;
	line-height: 22px;
	min-height: 42px;
	text-decoration: none;
	-webkit-user-select: none;
	user-select: none;
}

.dropdown-item:hover {
	background-color: #f5f4f1;
	color: #38312f;
}

@media screen and (max-width: 1024px) {
	.App {
		flex-direction: column;
	}

	header {
		display: flex;
		align-items: center;
		background: #353441;
		border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
		padding: 4px 0 0 12px;
	}

	nav {
		display: flex;
	}

	.chatBox {
		position: static;
	}

	.chatPromptWrapper {
		padding: 12px;
	}

	.stop-message {
		right: 5px;
		font-size: 13px;
		padding: 8px 15px;
	}

	.userSVG {
		transform: scale(0.5);
	}

	#avatar {
		width: 30px;
		height: 30px;
	}
}

@media screen and (min-width: 1536px) {
	.loginContainer h1 {
		font-size: 40px;
	}

	.loginContainer p {
		font-size: 25px;
	}

	#loginButton {
		font-size: 22px;
	}
}
