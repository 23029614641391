.CodeBlockClass {
	position: relative;
	overflow: auto;
	text-align: left;
}

.CopyBar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f5f5f5;
	padding: 5px 10px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border: 1px solid #c3c3c3;
	border-bottom: none;
}

.LanguageLabel {
	font-family: monospace;
	color: black;
}

.CopyButtonClass {
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
}

.CopyButtonClass:hover {
	background: inherit;
}

.CopyButtonContent {
	display: flex;
	align-items: center;
}

.CopyButtonContent span {
	margin-left: 5px;
}

.CopyButtonContent svg {
	width: 20px;
	height: 20px;
	fill: #000;
}

.InlineCodeBlock {
	font-family: monospace;
	font-size: 0.8rem;
	color: orange;
	border: 0.5px solid var(--secondary-bg-color);
	border-radius: 4px;
	padding: 2px;
	line-height: 1.7rem;
}
