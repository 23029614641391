.dropdownMenu {
	position: absolute;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	z-index: 1000;
	padding: 4px;
}

.dropdownOption {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 10px 10px;
	cursor: pointer;
	font-size: small;
	border-radius: 4px;
}

.dropdownOption:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}
